<template>
  <div class="container mescroll-touch">
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
          <img class="bannerImg" :src="item.File_Url" />
        </van-swipe-item>
      </van-swipe>
      <img class="goBack" src="@/assets/images/back_comm_icon@2x.png" @click="$router.go(-1)" />
    </div>
    <div class="tip">
      <span class="line"></span>
      <span class="tipFont">点击进入术式活动专区</span>
      <span class="line"></span>
    </div>
    <div class="menu">
      <div class="item" @click="toGu">
        <img class="icon" src="@/assets/images/ssy_icon1.png" alt="">
        <div class="item_title">
          <span>骨科</span>
          <img src="@/assets/images/open_comm_icon@2x.png" alt="">
        </div>
      </div>
      <div class="item" @click="toXinWai">
        <img class="icon" src="@/assets/images/ssy_icon2.png" alt="">
        <div class="item_title">
          <span>心外</span>
          <img src="@/assets/images/open_comm_icon@2x.png" alt="">
        </div>
      </div>
      <div class="item" @click="toChanke">
        <img class="icon" src="@/assets/images/ssy_icon3.png" alt="">
        <div class="item_title">
          <span>产科</span>
          <img src="@/assets/images/open_comm_icon@2x.png" alt="">
        </div>
      </div>
      <div class="item" @click="toPuWai">
        <img class="icon" src="@/assets/images/ssy_icon4.png" alt="">
        <div class="item_title">
          <span>普外</span>
          <img src="@/assets/images/open_comm_icon@2x.png" alt="">
        </div>
      </div>
    </div>
    <div class="block" v-for="(item, index) in List" :key="index">
      <div class="title">
        <div class="text">
          <img :src="item.Code == 'dklh' ? require('@/assets/images/dklh.png') : require('@/assets/images/zqnjx.png')" />
          <span>{{item.CodeName}}</span>
        </div>
        <div class="more" @click="toList(item.Code, item.TCode, item.CodeName)">
          <span>更多</span>
          <img src="@/assets/images/more_comm_icon@2x.png" />
        </div>
      </div>
      <div class="listWrapper mescroll-touch" v-if="item.ArtList.length > 0">
        <div class="list">
          <div class="item" v-for="(sonItem, sonIndex) in item.ArtList" :key="sonIndex" @click="toContentPage(sonItem.Id, sonItem.Type)">
            <div class="item_img">
              <img class="item_cover" :src="sonItem.Thumbnail" />
              <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
            </div>
            <div class="item_content">
              <span class="item_title">{{sonItem.Title}}</span>
              <div class="item_nums">
                <div class="nums">
                  <img src="@/assets/images/browse_comm_icon@2x.png" />
                  <span>{{sonItem.WatchCount}}</span>
                </div>
                <div class="nums">
                  <img src="@/assets/images/like_comm_icon@2x.png" />
                  <span>{{sonItem.LikeCount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
export default {
  name: 'OperativeMonth',
  data() {
    return {
      bannerList: [],
      List: []
    }
  },
  mixins: [mixin],
  created() {
    this.getBanner();
    this.getList();
  },
  methods: {
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=jcssy').then(res => {
        this.bannerList = res.Data;
      })
    },
    getList() {
      this.$axios.get('/Api/api/Web/Article/GetSZHFHArtList?TCode=jcssy|').then(res => {
        this.List = res.Data;
      })
    },
    toGu() {
      this.BuriedPoint(1, '大咖说', 'gkssjskf|gkdks|', `/caseCompetitionList?code=gkdks&tcode=gkssjskf|gkdks|&title=大咖说`);
      this.$router.push({
        path: '/caseCompetitionList',
        query: {
          code: 'gkdks',
          tcode: 'gkssjskf|gkdks|',
          title: '大咖说'
        }
      })
    },
    toXinWai() {
      this.BuriedPoint(1, '心外pk赛', 'xinwaizq|', `/extracardiacPrefecture`);
      this.$router.push('/extracardiacPrefecture')
    },
    toChanke() {
      this.$dialog.alert({
        title: '提示',
        message: '敬请期待',
      }).then(() => {});
    },
    toPuWai() {
      this.$dialog.alert({
        title: '提示',
        message: '敬请期待',
      }).then(() => {});
    },
    toList(Code, TCode, name) {
      this.BuriedPoint(1, name, TCode, `/operativeMonthList?code=${Code}&tcode=${TCode}&title=${name}`);
      this.$router.push({
        path: 'operativeMonthList',
        query: {
          code: Code,
          tcode: TCode,
          title: name
        }
      });
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          code: this.code,
          id: Id,
          type: Type
        }
      });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background-color: #F9F9F9;
  padding: 15px 12px;
  box-sizing: border-box;
  .banner {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px #ebedf0;
    .bannerImg {
      display: block;
      width: 100%;
    }
    .goBack {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 30px;
      height: 30px;
    }
  }
  .tip {
    display: flex;
    text-align: center;
    line-height: 32px;
    margin-top: 6px;
    .line {
      flex: 1;
      height: 1px;
      border-top: 1px solid rgba(151, 151, 151, 0.50);
      margin-top: 15px;
    }
    .tipFont {
      font-size: 10px;
      color: #333333;
      padding: 0 10px;
      opacity: 0.5;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    .item {
      width: calc(calc(100% / 4) - 10px);
      border-radius: 10px;
      text-align: center;
      background-color: #FFFFFF;
      box-shadow: 0 4px 12px #ebedf0;
      margin-bottom: 15px;
      .icon {
        width: 44px;
        margin: 8px 0 4px;
      }
      .item_title {
        position: relative;
        width: 78%;
        height: 16px;
        line-height: 16px;
        border-radius: 16px;
        background-color: #F5F5F5;
        margin: 0 auto 8px;
        font-size: 12px;
        color: #333333;
        img {
          position: absolute;
          right: 6px;
          top: 6px;
          transform: rotate(-90deg);
          width: 8px;
        }
      }
    }
  }
  .block {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    .title {
      display: flex;
      justify-content: space-between;
      .text {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #CA001B;
        font-weight: bold;
        img {
          width: 18px;
          margin-right: 8px;
        }
      }
      .more{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #CA001B;
        img {
          width: 12px;
          height: 12px;
          margin-left: 4px;
        }
      }
    }
    .listWrapper {
      display: flex;
      overflow-x: scroll;
      margin-top: 10px;
      .list {
        display: flex;
        flex-wrap: nowrap;
        .item {
          position: relative;
          width: 124px;
          border-radius: 4px;
          background-color: #F5F5F5;
          padding-bottom: 15px;
          overflow: hidden;
          .item_img {
            position: relative;
            overflow: hidden;
            .item_cover {
              display: block;
              width: 100%;
              height: 100%;
            }
            .live_status {
              position: absolute;
              top: 5px;
              left: 5px;
              display: inline-block;
              height: 15px;
              line-height: 15px;
              border-radius: 15px;
              padding: 0 6px;
              font-size: 9px;
              color: #FFFFFF;
              background-color: #000000;
              opacity: 0.5;
            }
            .playIcon {
              position: absolute;
              right: 12px;
              bottom: 12px;
              width: 30px;
              height: 30px;
            }
          }
          .item_content {
            padding: 5px;
            .item_title {
              font-size: 12px;
              color: #333333;
              overflow: hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              word-wrap: break-word;
              margin-bottom: 5px;
            }
            .item_nums {
              position: absolute;
              bottom: 6px;
              right: 8px;
              display: flex;
              justify-content: flex-end;
              .nums {
                display: flex;
                align-items: center;
                img {
                  width: 12px;
                  margin: 0 2px 0 6px;
                }
                span {
                  font-size: 11px;
                  color: #666666;
                }
              }
            }
          }
        }
        .item:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .listWrapper::-webkit-scrollbar { display: none; }
  }
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #CA001B !important;
}

/deep/ .swiper-pagination-bullet {
  width: 10px;
  height: 3px;
  border-radius: 3px;
  background-color: #999999;
  margin: 0;
}

/deep/ .swiper-pagination-bullets {
  bottom: 0px;
}

/deep/ .van-swipe__indicators {
  top: 90%;
}
</style>